.footer {
  // background-image: url(../../../../assets//images/motif-image.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  // mix-blend-mode: overlay;
  // opacity: 0.17;
  position: relative;
  width: 100%;

  .social-media {
    border-top: 1px solid #3a57b5;
    border-bottom: 1px solid #3a57b5;
  }

  .footer-background {
    background: #000000;
    // opacity: 1;
    opacity: 0.98;
  }
}

.custom-yellow-bg {
  background-color: #b48523;
}
