input[type="radio"] + label span {
    transition: .2s,
    transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
    transform: scale(1.2);
}

input[type="radio"]:checked + label span {
    background-color: #89724E;
    box-shadow: 0px 0px 0px 2px white inset;
}
