.header-container {
    position: relative;
    .header-background {
    //   background-image: url(../../../../assets/images/header-bg-image.jpg);
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      mix-blend-mode: luminosity;
      opacity: 0.2;
      padding-bottom: 70px;
      height: 260px;
      position: absolute;
      top: 0;
      transform: rotate(-180deg);
    }
  }
  
  .header-popup-dismiss {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .notification-container {
    position: relative;
  
    .header-popup {
      position: absolute;
      // padding: 12px;
      top: 66px;
      left: -100px;
      border-radius: 2px;
      // border: 2px solid #dadada;
      background-color: #ffffff;
      z-index: 3;
    }
  
    .hide {
      display: none;
    }
  
    /* Right triangle placed top left flush. */
    .tri-right:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      top: -25px;
      left: 112px;
      border: 12px solid;
      border-color: #dadada transparent transparent transparent;
      transform: rotate(-180deg);
    }
    .tri-right.left-top:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      // left: -20px;
      // right: auto;
      top: 38px;
      left: -14px;
      // top: 0px;
      // bottom: auto;
      border: 22px solid;
  
      border-color: white transparent transparent transparent;
    }
  }
  
  .profile-container {
    position: relative;
  
    .header-popup {
      position: absolute;
      top: 66px;
      right: -20px;
      border-radius: 2px;
      border: 1px solid #dadada;
      background-color: #ffffff;
      z-index: 3;
    }
  
    .hide {
      display: none;
    }
  
    /* Right triangle placed top left flush. */
    .tri-left:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      top: -25px;
      right: 34px;
      border: 12px solid;
      border-color: #dadada transparent transparent transparent;
      transform: rotate(-180deg);
    }
    .tri-left.left-top:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      // left: -20px;
      // right: auto;
      top: 38px;
      left: -14px;
      // top: 0px;
      // bottom: auto;
      border: 22px solid;
  
      border-color: white transparent transparent transparent;
    }
  }

  .profileNav {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: "auto 80px";
  }

  .profileNavBig {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    background-position: center;
  }

  .notificationCountFew {
    position: absolute;
    top: -9px;
    left: 12px;
    color: white;
    background-color: red;
    font-size: 13px;
    text-align: center;
    border-radius: 50%;
    font-weight: 400;
    padding: 0px 3px;
  }

  .notificationCountMore {
    position: absolute;
    top: -8px;
    left: 10px;
    color: white;
    background-color: red;
    font-size: 13px;
    text-align: center;
    border-radius: 50%;
    font-weight: 400;
    // padding: 0px 1px;
  }
