@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainBackground {
  background-color: #fcf7ea;
  background-repeat: no-repeat;
}

.buttonsColor {
  background-color: #89724e;
}

.stepsColor {
  background-color: #ab892c;
}

.textColor {
  color: #ab892c;
}

.borderColor {
  border: 1px solid #fdecbd;
}

.inputError {
  color: rgb(255, 0, 0);
}

.react-tel-input .form-control {
  border: none !important;
  border-radius: 5px;
  height: 3rem !important;
  width: 100% !important;
}

.react-tel-input .selected-flag {
  width: 43px !important;
}

.App-logo {
  /* width: 20vmin; */
  height: 20vmin;
  pointer-events: none;
}

.application-form {
  height: 75%;
}

.communication-side {
  height: 700px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.containerClass {
  background-color: #fdecbd;
}

.marginBottom {
  margin-bottom: 5vh;
}

.image-height {
  height: 37rem;
}
